// @flow
import styled from '@emotion/styled'
import * as Fonts from '../fonts'
import { media } from '../dimensions'
import Colors from '../colors'

export const Header = styled.div`
  position: relative;
  padding-bottom: 16px;

  ${media.tablet`
    padding-bottom: 32px;
  `};
`

Header.Background = styled.div`
  background: ${Colors.secondary};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

Header.Title = styled.h1`
  ${Fonts.Title};
  position: relative;
  color: ${Colors.white};
  text-align: center;
  margin-bottom: 24px;

  ${media.tablet`
    margin: 0 auto 56px;
    width: 70%
  `};
`

Header.SectionTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  span {
    display: block;
    flex: 1;
    height: 1px;
    background: ${Colors.util.fade({ color: Colors.white, amount: 0.75 })};
    position: relative;
  }

  h3 {
    ${Fonts.Caption};
    color: ${Colors.util.fade({ color: Colors.white, amount: 0.25 })};
    position: relative;
    text-align: center;
    text-transform: uppercase;
    margin: 0 16px;
  }
`

Header.Buttons = styled.div`
  display: grid;
  position: relative;
  margin-bottom: 40px;
  grid-template-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  ${media.tablet`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};
`

export const Features = styled.div`
  text-align: center;
`

export const Feature = styled.div`
  padding: 56px 0;

  ${media.tablet`
    padding: 72px 0px 0px;
  `};
`

Feature.Icon = styled.img`
  margin-bottom: 16px;
  transition: transform 0.25s;

  ${media.tablet`
    margin-bottom: 24px;
  `};
`

Feature.Title = styled.h2`
  ${Fonts.Title};
  margin-bottom: 16px;
`

Feature.Description = styled.h3`
  ${Fonts.Subtitle};
  margin-bottom: 56px;

  ${media.tablet`
    /* margin-bottom: 0; */
  `}
`

Feature.BannerImage = styled.div`
  display: none;

  ${media.tablet`
    display: block;
    height: 344px;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 72px;

    .gatsby-image-wrapper {
      height: 100%;
    }
  `};
`

const featureHighlightFadeDuration = '0.7s'

Feature.HighlightContainer = styled.div`
  display: flex;
`

Feature.HighlightContainer.ImageContainer = styled.div`
  height: ${props => `${props.nItems * 200}px`};
  height: 600px;
  flex: 3;
  position: relative;
`

Feature.HighlightContainer.ImageContainer.Image = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: ${props => `${props.nItems * 200}px`} !important;
  height: 600px !important;
  width: 100%;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity ${featureHighlightFadeDuration};

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    right: -125px;

    img {
      height: ${props => `${props.nItems * 200}px`} !important;
      height: 600px !important;
      width: auto !important;
      right: 0;
      object-fit: contain !important;
    }

    ${props => media.tablet`
      right: -560px;
      top: 100px;

      img {
        height: ${`${props.nItems * 200 * 0.6}px`} !important;
        height: 60% !important;
      }
      
    `};

    ${props => media.desktop`
      right: -175px;
      top: 0;
      
      img {
        height: ${`${props.nItems * 200}px`} !important;
        height: 100% !important;
      }
    `};

    ${media.desktopHD`
      right: -125px;
    `};
  }
`

Feature.HighlightContainer.VerticalLine = styled.div`
  width: 1px;
  background: ${Colors.grey6};
  position: relative;
`

Feature.HighlightContainer.VerticalLine.MaskTop = styled.div`
  height: 100px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, ${Colors.grey6} 0%, ${Colors.white} 100%);
`

Feature.HighlightContainer.VerticalLine.MaskBottom = styled.div`
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, ${Colors.grey6} 0%, ${Colors.white} 100%);
`

Feature.HighlightContainer.List = styled.div`
  flex: 2;
  background: ${Colors.white};
  /* margin-right: 112px; */
  padding-left: 32px;
  /* background: red; */
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.nItems === 1 ? 'center' : 'space-between')};
  padding-bottom: 56px;
`

Feature.HighlightSlim = styled.div`
  text-align: left;
  margin-bottom: 24px;
`

Feature.HighlightSlim.Title = styled.h4`
  ${Fonts.Subtitle2};
  margin-bottom: 16px;
`

Feature.HighlightSlim.Image = styled.div`
  .gatsby-image-wrapper {
    width: 130%;
    left: -15%;
  }
`

Feature.HighlightSlim.Description = styled.p`
  ${Fonts.Body};
`

Feature.HighlightSlim.DescriptionRich = styled.div`
  ${Fonts.Body};

  ol,
  ul {
    ${Fonts.Body};
    opacity: 1;
    padding-left: 20px;
  }

  p {
    ${Fonts.Body};
    opacity: 1;
  }
`

Feature.Highlight = styled.div`
  text-align: left;
  position: relative;
  cursor: default;
`

Feature.Highlight.Circle = styled.div`
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  background: ${props => (props.active ? Colors.grey6 : Colors.white)};
  border: 2px solid ${props => (props.active ? Colors.grey1 : Colors.grey6)};
  border-radius: 16px;
  position: absolute;
  top: -4px;
  left: -48px;
  transition: background ${featureHighlightFadeDuration}, border ${featureHighlightFadeDuration};
`

Feature.Highlight.Title = styled.h4`
  ${Fonts.Subtitle2};
  margin-bottom: 16px;
`

Feature.Highlight.Description = styled.p`
  ${Fonts.Body};
  opacity: ${props => (props.active ? 1 : 0.6)};
  transition: opacity ${featureHighlightFadeDuration};
`

Feature.Highlight.DescriptionRich = styled.div`
  ${Fonts.Body};
  opacity: ${props => (props.active ? 1 : 0.6)};
  transition: opacity ${featureHighlightFadeDuration};

  ol,
  ul {
    ${Fonts.Body};
    opacity: 1;
    padding-left: 20px;
  }

  p {
    ${Fonts.Body};
    opacity: 1;
  }
`
