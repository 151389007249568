// @flow

import styled from '@emotion/styled'
import { media } from '../../styles/dimensions'
import Colors from '../../styles/colors'
import * as Fonts from '../../styles/fonts'
import { ButtonContainer as Button } from '../Button/style'

export const Container = styled.form`
  background: ${Colors.offWhite};
  border-radius: 6px;
  padding: 40px 24px;
  text-align: center;
  margin: 24px 0;

  ${media.desktop`
    padding: 40px 80px;
    text-align: left;
    display: flex;
    align-items: center;
    margin: 72px 0;

    > div:first-of-type {
      margin-right: 96px;
      flex: 1;
    }

    > div:last-of-type {
      flex: 1;
    }
  `};
`

export const Title = styled.h2`
  ${Fonts.Title2};
  margin: 0 0 8px;
`

export const Description = styled.p`
  ${Fonts.Body};
  margin: 0 0 40px;

  ${media.desktop`
    margin: 0;
  `};
`

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 16px;

  ${Button} {
    flex: 1;
  }
`

export const InputContainer = styled.div`
  background: ${Colors.util.fade({ color: Colors.pureBlack, amount: 0.95 })};
  border-radius: 6px;
  display: flex;
  height: 56px;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 16px;

  ${Button} {
    display: none;
  }

  ${media.desktop`
    margin-bottom: 0;

    ${Button} {
      display: block;
    } 
  `};
`

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  color: ${Colors.grey4};
  margin: 0 16px;
`

export const Input = styled.input`
  flex: 1;
  height: 100%;
  padding: 0 16px 0 0;
  margin: 0;
  border: 0;
  background: transparent;
  outline: none;
  font-size: 16px;
  color: ${Colors.grey4};
  letter-spacing: 0;
  line-height: 16px;
  width: 50px;
`
