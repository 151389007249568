// @flow

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import validate from 'validate.js'
import Analytics from '../../utils/analytics'
import Notification from '../Notification'
import RenderIf from '../RenderIf'
import TextInput from '../TextInput'
import Button from '../Button'
import API from '../../services/api'
import { NOTIFICATION_TYPES } from '../../constants'
import { Container, Title, Description, ButtonContainer } from './style'

type State = {
  isSubmitting: boolean,
  formData: {|
    email: string,
  |},
  validationErrors: {
    email?: Array<string>,
  },
  error: null | string,
  success: null | string,
}

const defaultFormData = {
  email: '',
}

class NewsletterSignUp extends React.Component<*, State> {
  state = {
    isSubmitting: false,
    formData: defaultFormData,
    validationErrors: {},
    error: null,
    success: null,
  }

  validationConstraints = {
    email: {
      presence: { allowEmpty: false },
    },
  }

  validateFields = () => {
    const { formData } = this.state
    const validationErrors = validate(formData, this.validationConstraints)

    if (validationErrors) {
      Analytics.event({
        category: 'Newsletter',
        action: 'Validation Errors',
      })

      this.setState({
        validationErrors,
        error: 'Please correct the items in red and try again.',
        success: null,
      })
      return false
    }

    this.setState({ validationErrors: {}, success: null, error: null })
    return true
  }

  handleEmailChange = (email: string) => {
    const { formData } = this.state

    const updatedFormData = {
      ...formData,
      email,
    }

    this.setState({ formData: updatedFormData })
  }

  handleSubmit = (e?: { preventDefault: Function }) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    const { formData } = this.state

    Analytics.event({
      category: 'Newsletter',
      action: 'Submit',
    })

    if (this.validateFields()) {
      this.setState({ isSubmitting: true, error: null, success: null })

      API.newsletterSignUp({ email: formData.email })
        .then(() => {
          this.setState({
            isSubmitting: false,
            success: "Message received! We'll be in touch shortly.",
            error: null,
            formData: { email: '' },
          })

          Analytics.event({
            category: 'Newsletter',
            action: 'Subscribe Success',
          })
        })
        .catch(error => {
          this.setState({ isSubmitting: false, success: null, error })

          Analytics.event({
            category: 'Newsletter',
            action: 'Subscribe Failure',
            label: `Error: ${error}`,
          })
        })
    }
  }

  render() {
    const { isSubmitting, formData, validationErrors, error, success } = this.state

    return (
      <StaticQuery
        query={newsletterSignUpQuery}
        render={data => {
          const {
            title,
            description: { description },
            placeholder,
            buttonLabel,
          } = data.allContentfulNewsletterSignUp.edges[0].node

          return (
            <Container onSubmit={this.handleSubmit}>
              <div>
                <Title>{title}</Title>
                <Description>{description}</Description>
              </div>
              <div>
                {error ? (
                  <Notification type={NOTIFICATION_TYPES.ERROR}>{error}</Notification>
                ) : null}
                {success ? (
                  <Notification type={NOTIFICATION_TYPES.SUCCESS}>{success}</Notification>
                ) : null}
                <RenderIf devices={['desktop', 'desktopHD']}>
                  <TextInput
                    placeholder={placeholder}
                    aria-label={placeholder}
                    value={formData.email}
                    disabled={isSubmitting}
                    onChange={this.handleEmailChange}
                    errors={validationErrors.email}
                    kind="grey"
                    iconLeft="email"
                    buttonLabel={buttonLabel}
                    onButtonClick={this.handleSubmit}
                    loading={isSubmitting}
                  />
                </RenderIf>

                <RenderIf devices={['phone', 'tablet']}>
                  <TextInput
                    placeholder={placeholder}
                    aria-label={placeholder}
                    value={formData.email}
                    disabled={isSubmitting}
                    onChange={this.handleEmailChange}
                    errors={validationErrors.email}
                    kind="grey"
                    iconLeft="email"
                    loading={isSubmitting}
                  />
                  <ButtonContainer>
                    <Button label={buttonLabel} type="submit" loading={isSubmitting} />
                  </ButtonContainer>
                </RenderIf>
              </div>
            </Container>
          )
        }}
      />
    )
  }
}

const newsletterSignUpQuery = graphql`
  query NewsletterSignUpQuery {
    allContentfulNewsletterSignUp {
      edges {
        node {
          title
          description {
            description
          }
          placeholder
          buttonLabel
        }
      }
    }
  }
`

export default NewsletterSignUp
