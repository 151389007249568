// @flow

import React from 'react'
import { graphql } from 'gatsby'
import slugify from 'slugify'
import Img from 'gatsby-image'
import isEmpty from 'lodash/isEmpty'
import anime from 'animejs'
import Analytics from '../utils/analytics'
import Layout from '../components/Layout'
import RenderIf from '../components/RenderIf'
import Button from '../components/Button'
import SEO from '../components/SEO'
import Nav from '../components/Nav'
import PaddedContent from '../components/PaddedContent'
import NewsletterSignUp from '../components/NewsletterSignUp'
import ContactFormWithEmailLink from '../components/ContactFormWithEmailLink'
import { type FluidWithWebp, type FixedWithWebp } from '../types'
import { Header, Features, Feature } from '../styles/pages/features'

const HIGHLIGHT_STATES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  UNSEEN: 'UNSEEN',
}

type Props = {
  data: {
    contentfulFeaturesPage: {|
      pageTitle: string,
      keywords: Array<string>,
      description: {
        description: string,
      },
      headingTitle: string,
      featuresSection1Title: string,
      featuresSection1Features: Array<FeatureT>,
      featuresSection2Title: string,
      featuresSection2Features: Array<FeatureT>,
      contactFormTitle: string,
      contactFormSubtitle: string,
      contactFormButtonLabel: string,
    |},
  },
  location: any,
}

type State = {
  highlightMouseOverMap: {
    [key: string]: {
      [key: string]: $Values<typeof HIGHLIGHT_STATES>,
    },
  },
}

type FeatureT = {|
  id: string,
  title: string,
  description: string,
  icon: {
    title: string,
    file: {
      url: string,
    },
  },
  bannerImage: {
    title: string,
    ...FluidWithWebp,
  },
  highlights: Array<{|
    id: string,
    title: string,
    description: string,
    descriptionRich?: {
      childContentfulRichText: {
        html: string,
      },
    },
    largeImage: {
      id: string,
      title: string,
      ...FixedWithWebp,
      ...FluidWithWebp,
    },
  |}>,
|}

const UNSEEN_CIRCLE_CLASS = 'inactive-circle-animation'

class FeaturesPage extends React.Component<Props, State> {
  state = {
    highlightMouseOverMap: {},
  }

  circleAnimationTimeline: any = null

  componentDidMount() {
    Analytics.pageview()

    this.circleAnimationTimeline = anime
      .timeline({
        targets: [`.${UNSEEN_CIRCLE_CLASS}`],
        delay: anime.stagger(500, { direction: 'normal' }),
        complete: () => {
          this.circleAnimationTimeline.restart()
        },
      })
      .add({
        targets: [`.${UNSEEN_CIRCLE_CLASS}`],
        scale: 2,
        opacity: 0,
        duration: 1000,
        easing: 'easeOutQuad',
        endDelay: 0,
      })
  }

  componentWillUnmount = () => {
    anime.remove(`.${UNSEEN_CIRCLE_CLASS}`)
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (isEmpty(state.highlightMouseOverMap) && props.data && props.data.contentfulFeaturesPage) {
      const features = [
        ...props.data.contentfulFeaturesPage.featuresSection1Features,
        ...props.data.contentfulFeaturesPage.featuresSection2Features,
      ]

      return {
        highlightMouseOverMap: features.reduce(
          (featureSum, feature) => ({
            ...featureSum,
            [feature.id]: feature.highlights.reduce(
              (highlightSum, highlight, highlightIndex) => ({
                ...highlightSum,
                [highlight.id]:
                  highlightIndex === 0 ? HIGHLIGHT_STATES.ACTIVE : HIGHLIGHT_STATES.UNSEEN,
              }),
              {},
            ),
          }),
          {},
        ),
      }
    }

    return null
  }

  handleHighlightMouseOver = ({
    featureId,
    highlightId,
  }: {
    featureId: string,
    highlightId: string,
  }) => {
    const { highlightMouseOverMap: oldMap } = this.state

    const highlightMouseOverMap = {
      ...oldMap,
    }

    // Mark old ACTIVE as INACTIVE

    highlightMouseOverMap[featureId] = Object.entries(highlightMouseOverMap[featureId]).reduce(
      (sum, [key, val]) => {
        let state = val

        if (val === HIGHLIGHT_STATES.ACTIVE) {
          state = HIGHLIGHT_STATES.INACTIVE
        }

        // If this is the one we're highlighting, make it ACTIVE
        if (key === highlightId) {
          state = HIGHLIGHT_STATES.ACTIVE
        }

        return {
          ...sum,
          [key]: state,
        }
      },
      {},
    )

    this.setState({
      highlightMouseOverMap,
    })
  }

  renderFeature = (feature: FeatureT) => {
    const { highlightMouseOverMap } = this.state

    return (
      <React.Fragment key={feature.id}>
        <Feature id={slugify(feature.title, { lower: true })}>
          <Feature.Icon src={feature.icon.file.url} alt={feature.icon.title} />
          <PaddedContent>
            <Feature.Title>{feature.title}</Feature.Title>
            <Feature.Description>{feature.description}</Feature.Description>
          </PaddedContent>
          {feature.bannerImage && (
            <PaddedContent>
              <Feature.BannerImage>
                <Img fluid={feature.bannerImage.fluid} alt={feature.bannerImage.title} />
              </Feature.BannerImage>
            </PaddedContent>
          )}
          <RenderIf devices={['phone']}>
            <PaddedContent>
              {feature.highlights.map(highlight => (
                <Feature.HighlightSlim key={highlight.id}>
                  <Feature.HighlightSlim.Image>
                    <Img fluid={highlight.largeImage.fluid} alt={highlight.largeImage.title} />
                  </Feature.HighlightSlim.Image>
                  <Feature.HighlightSlim.Title>{highlight.title}</Feature.HighlightSlim.Title>
                  {highlight.description && (
                    <Feature.HighlightSlim.Description>
                      {highlight.description}
                    </Feature.HighlightSlim.Description>
                  )}

                  {highlight.descriptionRich && (
                    <Feature.HighlightSlim.DescriptionRich
                      dangerouslySetInnerHTML={{
                        __html: `<div>${highlight.descriptionRich.childContentfulRichText.html}</div>`,
                      }}
                    />
                  )}
                </Feature.HighlightSlim>
              ))}
            </PaddedContent>
          </RenderIf>

          <RenderIf devices={['tablet', 'desktop', 'desktopHD']}>
            <PaddedContent>
              <Feature.HighlightContainer>
                <Feature.HighlightContainer.ImageContainer nItems={feature.highlights.length}>
                  {feature.highlights.map(highlight => {
                    const isActive =
                      highlightMouseOverMap[feature.id][highlight.id] === HIGHLIGHT_STATES.ACTIVE

                    if (highlight.largeImage) {
                      return (
                        <Feature.HighlightContainer.ImageContainer.Image
                          key={highlight.id}
                          active={isActive}
                          nItems={feature.highlights.length}
                        >
                          <Img
                            fixed={highlight.largeImage.fixed}
                            alt={highlight.largeImage.title}
                          />
                        </Feature.HighlightContainer.ImageContainer.Image>
                      )
                    }

                    return null
                  })}
                </Feature.HighlightContainer.ImageContainer>
                <Feature.HighlightContainer.VerticalLine>
                  {feature.highlights.length === 1 && (
                    <Feature.HighlightContainer.VerticalLine.MaskTop />
                  )}

                  <Feature.HighlightContainer.VerticalLine.MaskBottom />
                </Feature.HighlightContainer.VerticalLine>
                <Feature.HighlightContainer.List nItems={feature.highlights.length}>
                  {feature.highlights.map(highlight => {
                    const isActive =
                      highlightMouseOverMap[feature.id][highlight.id] === HIGHLIGHT_STATES.ACTIVE
                    const isUnseen =
                      highlightMouseOverMap[feature.id][highlight.id] === HIGHLIGHT_STATES.UNSEEN

                    return (
                      <Feature.Highlight
                        key={highlight.id}
                        onMouseEnter={() => {
                          this.handleHighlightMouseOver({
                            featureId: feature.id,
                            highlightId: highlight.id,
                          })
                        }}
                        onFocus={() => {
                          this.handleHighlightMouseOver({
                            featureId: feature.id,
                            highlightId: highlight.id,
                          })
                        }}
                      >
                        <RenderIf condition={isUnseen}>
                          <Feature.Highlight.Circle
                            className={isUnseen ? UNSEEN_CIRCLE_CLASS : ''}
                          />
                        </RenderIf>
                        <Feature.Highlight.Circle active={isActive} />
                        <Feature.Highlight.Title>{highlight.title}</Feature.Highlight.Title>
                        {highlight.description && (
                          <Feature.Highlight.Description active={isActive}>
                            {highlight.description}
                          </Feature.Highlight.Description>
                        )}

                        {highlight.descriptionRich && (
                          <Feature.Highlight.DescriptionRich
                            active={isActive}
                            dangerouslySetInnerHTML={{
                              __html: `<div>${highlight.descriptionRich.childContentfulRichText.html}</div>`,
                            }}
                          />
                        )}
                      </Feature.Highlight>
                    )
                  })}
                </Feature.HighlightContainer.List>
              </Feature.HighlightContainer>
            </PaddedContent>
          </RenderIf>
        </Feature>
      </React.Fragment>
    )
  }

  render() {
    const { data, location } = this.props

    const {
      pageTitle,
      keywords,
      description: { description },
      headingTitle,
      featuresSection1Title,
      featuresSection1Features,
      featuresSection2Title,
      featuresSection2Features,
      contactFormTitle,
      contactFormSubtitle,
      contactFormButtonLabel,
    } = data.contentfulFeaturesPage

    return (
      <Layout>
        <SEO title={pageTitle} description={description} keywords={keywords} location={location} />
        <Header>
          <Header.Background />
          <Nav location={location} />
          <PaddedContent>
            <Header.Title>{headingTitle}</Header.Title>
            <Header.SectionTitle>
              <span />
              <h3>{featuresSection1Title}</h3>
              <span />
            </Header.SectionTitle>
            <Header.Buttons>
              {featuresSection1Features.map(feature => (
                <Button
                  key={feature.id}
                  to={`/features#${slugify(feature.title, { lower: true })}`}
                  label={feature.title}
                  kind="outline-on-dark"
                  analytics={`Features Heading: ${feature.title}`}
                />
              ))}
            </Header.Buttons>
            <Header.SectionTitle>
              <span />
              <h3>{featuresSection2Title}</h3>
              <span />
            </Header.SectionTitle>
            <Header.Buttons>
              {featuresSection2Features.map(feature => (
                <Button
                  key={feature.id}
                  to={`/features#${slugify(feature.title, { lower: true })}`}
                  label={feature.title}
                  kind="outline-on-dark"
                  analytics={`Features Heading: ${feature.title}`}
                />
              ))}
            </Header.Buttons>
          </PaddedContent>
        </Header>
        <Features>
          {featuresSection1Features.map(this.renderFeature)}
          <PaddedContent>
            <NewsletterSignUp />
          </PaddedContent>
          {featuresSection2Features.map(this.renderFeature)}
        </Features>
        <ContactFormWithEmailLink
          title={contactFormTitle}
          subtitle={contactFormSubtitle}
          buttonLabel={contactFormButtonLabel}
        />
      </Layout>
    )
  }
}

export default FeaturesPage

export const query = graphql`
  query FeaturesPageQuery {
    contentfulFeaturesPage {
      pageTitle
      keywords
      description {
        description
      }
      headingTitle
      featuresSection1Title
      featuresSection1Features {
        id
        title
        description
        icon {
          title
          file {
            url
          }
        }
        bannerImage {
          title
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        highlights {
          id
          title
          description
          descriptionRich {
            childContentfulRichText {
              html
            }
          }
          largeImage {
            id
            title
            fixed(height: 600, width: 1200, quality: 100) {
              ...GatsbyContentfulFixed_withWebp
            }
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
      featuresSection2Title
      featuresSection2Features {
        id
        title
        description
        icon {
          title
          file {
            url
          }
        }
        bannerImage {
          title
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
        highlights {
          id
          title
          description
          descriptionRich {
            childContentfulRichText {
              html
            }
          }
          largeImage {
            id
            title
            fixed(height: 600, width: 1200, quality: 100) {
              ...GatsbyContentfulFixed_withWebp
            }
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
      contactFormTitle
      contactFormSubtitle
      contactFormButtonLabel
    }
  }
`
